import { withApollo } from '@apollo/react-hoc'
import {
  Flex,
  Heading,
  IconButton,
  HStack,
  VStack,
  Box,
  Stack,
  Button,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { ENFORCEMENT_TYPE } from '@/utils/constants'
import {
  PRODUCT_TYPE_TO_ENFORCEMENT_TYPE,
  stageEnforcementButtonDisabledReasonsTooltip,
} from '@/utils/enforcement_utils'
import { useEffect, useRef, useState } from 'react'
import { renderTrademarkSelector } from './suggested_enforcements_form'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import {
  EnforcementPlatformType,
  EnforcementPlatformTypeLabels,
  EnforcementMethodLabels,
  StagingDisabledReason,
  AppEnforcementPlatformType,
  ProductType,
  AppEnforcementPlatformTypeLabels,
  SuggestedEnforcementStatus,
  EnforcementType,
  SocialMediaContentType,
} from '@/generated/enums'
import {
  GenerateSuggestedEnforcementsInput,
  useGenerateSuggestedEnforcementsMutation,
  useGetTrademarksQuery,
  useUpdateSuggestedEnforcementMutation,
} from '@/generated/graphql'
import { IoMdAddCircle, IoMdCheckmark, IoMdClipboard, IoMdClose } from 'react-icons/io'
import DetailModal from '../shared_reports_table/detail_modal'
import IndividualSuggestedEnforcementDetails from './individual_suggested_enforcement_details'
import DisabledEnforcementsTable from './disabled_enforcements_table'
import DetailNavBar from '../shared/back_button'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_BUTTON_GREY,
  DOPPEL_CYBER_BLUE,
  DOPPEL_FIREWALL_ORANGE,
  DOPPEL_SUCCESS,
} from '@/utils/style'
import DropdownSelector from '../shared/dropdown_selector'
import {
  createGenerateSuggestedEnforcementsSingleFormInput,
  createGenerateSuggestedEnforcementsUserEnforcementInput,
  fillInMissingDisabledEnforcementFields,
  fillInMissingSuggestedEnforcementFields,
} from '@/utils/suggested_enforcements/create_suggested_enforcements_utils'
import {
  DisabledSuggestedEnforcement,
  SuggestedAndDisabledEnforcements,
  SuggestedEnforcement,
} from '@/utils/suggested_enforcements/suggested_enforcement_types'
import {
  moveEnforcement,
  renderEnforcementRequests,
} from '@/utils/suggested_enforcements/display_enforcement_utils'
import { getActiveOrStagedDisabledReasonLabel } from '@/utils/suggested_enforcements/post_process_suggested_enforcement_utils'
import { useUserID } from '@/hooks/id_token_claims'

type Props = {
  formResponse: any
  closeSuggestedEnforcementsDisplay: any
  returnToTableView: any
  addSuggestedEnforcementsToForm: any
  alreadyStagedEnforcements: any
  useDefaults?: boolean
  client: any
  product: any
}

const DROPDOWN_PLATFORM_TYPES = [
  EnforcementPlatformType.BUILDER,
  EnforcementPlatformType.SHORTENER,
  EnforcementPlatformType.COUNTRY_CODE_TLD,
  EnforcementPlatformType.GENERIC_TLD,
  EnforcementPlatformType.REGISTRAR,
  EnforcementPlatformType.HOSTING_PROVIDER,
  EnforcementPlatformType.WEBSITE_OWNER,
]

const DROPDOWN_PLATFORM_TYPES_ITEMS = DROPDOWN_PLATFORM_TYPES.map((type) => {
  return { value: type, label: EnforcementPlatformTypeLabels[type] }
})

const APP_DROPDOWN_PLATFORM_TYPES = [
  AppEnforcementPlatformType.LEGITIMATE,
  AppEnforcementPlatformType.THIRD_PARTY_APK,
]

const APP_DROPDOWN_PLATFORM_TYPES_ITEMS = APP_DROPDOWN_PLATFORM_TYPES.map((type) => {
  return { value: type, label: AppEnforcementPlatformTypeLabels[type] }
})

const SuggestedEnforcementsDisplay: React.FC<Props> = ({
  formResponse,
  closeSuggestedEnforcementsDisplay,
  returnToTableView,
  addSuggestedEnforcementsToForm,
  alreadyStagedEnforcements,
  product,
}) => {
  const overlayRef = useRef<HTMLInputElement>()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [userId] = useUserID()
  const [suggestedEnforcementRequests, setSuggestedEnforcementRequests] = useState([])

  const [generateSuggestedEnforcementsMutation] =
    useGenerateSuggestedEnforcementsMutation()

  const [updateSuggestedEnforcements] = useUpdateSuggestedEnforcementMutation()

  const [rejectedEnforcementRequests, setRejectedEnforcementRequests] = useState([])

  const [stagedEnforcementRequests, setStagedEnforcementRequests] = useState([])

  const [shownEnforcementNotGeneratedReasons, setShownEnforcementNotGeneratedReasons] =
    useState([])
  const [
    hiddenEnforcementNotGeneratedReasons,
    setHiddenEnforcementNotGeneratedReasons,
  ] = useState([])

  const [showIndividualEnforcementDetails, setShowIndividualEnforcementDetails] =
    useState(false)
  const [creatingNewEnforcement, setCreatingNewEnforcement] = useState(false)
  const [selectedEnforcementType, setSelectedEnforcementType] = useState('')
  const [selectedEnforcementPlatformType, setSelectedEnforcementPlatformType] =
    useState('')
  const [selectedEnforcement, setSelectedEnforcement] = useState(null)
  const [selectedEnforcementPos, setSelectedEnforcementPos] = useState(null)
  const [selectedTrademarkId, setSelectedTrademarkId] = useState('')
  const [selectedTrademarkMark, setSelectedTrademarkMark] = useState('')
  const [selectedContentType, setSelectedContentType] = useState('')
  const [reasonsCreateNewEnforcementDisabled, setReasonsCreateNewEnforcementDisabled] =
    useState([])
  const toast = useToast()

  const skippedSuggestedEnforcementForm = Object.keys(formResponse).length === 0

  const trademarksWhereClause = {
    spoofing_entity_id: { _eq: selectedSpoofReport?.spoofing_entity?.id },
  }

  const { data: trademarksData } = useGetTrademarksQuery({
    variables: {
      offset: 0,
      limit: null,
      where: trademarksWhereClause,
    },
  })

  const trademarks = (trademarksData && trademarksData.trademarks) || []

  useEffect(() => {
    const reasons = []
    if (creatingNewEnforcement) {
      if (!selectedEnforcementType) {
        reasons.push(StagingDisabledReason.MISSING_ENFORCEMENT_TYPE)
      }
      if (!selectedEnforcementPlatformType && product === ProductType.DOMAIN) {
        reasons.push(StagingDisabledReason.MISSING_PLATFORM_TYPE)
      }
      if (
        selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK &&
        !selectedTrademarkMark
      ) {
        reasons.push(StagingDisabledReason.MISSING_TRADEMARK)
      }
      if (product === ProductType.SOCIAL_MEDIA && !selectedContentType) {
        reasons.push(StagingDisabledReason.MISSING_CONTENT_TYPE)
      }
    }
    setReasonsCreateNewEnforcementDisabled(reasons)
  }, [
    product,
    creatingNewEnforcement,
    selectedEnforcementType,
    selectedEnforcementPlatformType,
    selectedTrademarkMark,
    selectedContentType,
  ])

  const generateUserEnforcement = async () => {
    const generate_suggested_enforcements_input =
      createGenerateSuggestedEnforcementsUserEnforcementInput(
        selectedEnforcementType as EnforcementType,
        product,
        selectedSpoofReport?.id,
        alreadyStagedEnforcements,
        selectedEnforcementPlatformType
          ? (selectedEnforcementPlatformType as EnforcementPlatformType)
          : null,
        selectedTrademarkId ? selectedTrademarkId : null,
        selectedContentType ? selectedContentType : null,
        formResponse.url_type ? formResponse.url_type : null,
      )
    await callGenerateSuggestedEnforcements(generate_suggested_enforcements_input, true)
  }

  const assignIdsToEnforcements = (enforcements) => {
    return enforcements.map((enforcement, index) => ({
      ...enforcement,
      id: index,
    }))
  }

  const checkIfSuggestedEnforcementAlreadyActiveOrStaged = (
    suggestedEnforcement: SuggestedEnforcement,
  ) => {
    return getActiveOrStagedDisabledReasonLabel(
      suggestedEnforcement,
      selectedSpoofReport,
      alreadyStagedEnforcements,
      stagedEnforcementRequests,
    )
  }

  const getDropdownPlatformTypes = () => {
    switch (product) {
      case ProductType.MOBILE_APP:
        return APP_DROPDOWN_PLATFORM_TYPES_ITEMS
      case ProductType.DOMAIN:
        return DROPDOWN_PLATFORM_TYPES_ITEMS
      default:
        return []
    }
  }

  const callGenerateSuggestedEnforcements = async (
    generate_suggested_enforcements_input: GenerateSuggestedEnforcementsInput,
    isUserEnforcement = false,
  ) => {
    let suggestedAndDisabledEnforcements = null
    await generateSuggestedEnforcementsMutation({
      variables: {
        generate_suggested_enforcements_input: generate_suggested_enforcements_input,
      },
      onCompleted: (data) => {
        suggestedAndDisabledEnforcements = data.generate_suggested_enforcements
      },
    })

    if (
      !suggestedAndDisabledEnforcements ||
      !suggestedAndDisabledEnforcements.generated_suggested_enforcements
    ) {
      toast({
        title: 'Failed to generate suggested enforcements',
        status: 'error',
        isClosable: true,
      })
      return
    } else {
      const enforcements =
        suggestedAndDisabledEnforcements.generated_suggested_enforcements
      handleGenerateSuggestedEnforcementsResult(enforcements, isUserEnforcement)
    }
  }

  useEffect(() => {
    const generateSuggestedEnforcements = async () => {
      const generate_suggested_enforcements_input =
        createGenerateSuggestedEnforcementsSingleFormInput(
          formResponse,
          selectedSpoofReport?.id,
          product,
          alreadyStagedEnforcements,
        )
      await callGenerateSuggestedEnforcements(generate_suggested_enforcements_input)
    }
    if (!skippedSuggestedEnforcementForm) {
      generateSuggestedEnforcements()
    }
  }, [formResponse])

  const handleGenerateSuggestedEnforcementsResult = (
    suggestedAndDisabledEnforcements: SuggestedAndDisabledEnforcements,
    isUserEnforcement: boolean,
  ) => {
    addAllSuggestedEnforcements(
      suggestedAndDisabledEnforcements.suggested_enforcements,
      isUserEnforcement,
    )
    addDisabledSuggestedEnforcements(
      suggestedAndDisabledEnforcements.disabled_enforcements,
    )
  }

  const addAllSuggestedEnforcements = (
    allSuggestedEnforcements: SuggestedEnforcement[],
    isUserEnforcement: boolean,
  ) => {
    const autoStagedEnforcements = []
    const suggestedEnforcements = []
    for (const suggestedEnforcement of allSuggestedEnforcements) {
      const updatedSuggestedEnforcement =
        fillInMissingSuggestedEnforcementFields(suggestedEnforcement)
      let newIndex = suggestedEnforcementRequests.length // used when adding single user enforcement, will be last index in either suggestedEnforcementRequests or stagedEnforcementRequests
      if (suggestedEnforcement.status === SuggestedEnforcementStatus.AUTO_STAGED) {
        autoStagedEnforcements.push(updatedSuggestedEnforcement)
        newIndex = stagedEnforcementRequests.length
      } else {
        suggestedEnforcements.push(updatedSuggestedEnforcement)
      }
      if (isUserEnforcement && allSuggestedEnforcements.length === 1) {
        setShowIndividualEnforcementDetails(true)
        setSelectedEnforcement({
          ...updatedSuggestedEnforcement,
          id: newIndex,
        })
        setSelectedEnforcementPos(newIndex)
        setSelectedTrademarkId('')
        setSelectedTrademarkMark('')
        setSelectedContentType('')
        setCreatingNewEnforcement(false)
      }
    }

    setSuggestedEnforcementRequests(
      assignIdsToEnforcements([
        ...suggestedEnforcementRequests,
        ...suggestedEnforcements,
      ]),
    )

    setStagedEnforcementRequests([
      ...stagedEnforcementRequests,
      ...autoStagedEnforcements,
    ])
  }

  const addDisabledSuggestedEnforcements = (
    disabledSuggestedEnforcements: DisabledSuggestedEnforcement[],
  ) => {
    const initiallyShownDisabledReasons = []
    const hiddenDisabledReasons = []
    for (const disabledSuggestedEnforcement of disabledSuggestedEnforcements) {
      const updatedDisabledEnforcement = fillInMissingDisabledEnforcementFields(
        disabledSuggestedEnforcement,
      )

      if (updatedDisabledEnforcement.shouldDisplayInitially) {
        initiallyShownDisabledReasons.push(updatedDisabledEnforcement)
      } else {
        hiddenDisabledReasons.push(updatedDisabledEnforcement)
      }
    }

    setShownEnforcementNotGeneratedReasons([
      ...shownEnforcementNotGeneratedReasons,
      ...initiallyShownDisabledReasons,
    ])

    setHiddenEnforcementNotGeneratedReasons([
      ...hiddenEnforcementNotGeneratedReasons,
      ...hiddenDisabledReasons,
    ])
  }

  const getCurrentEnforcementIndex = () => {
    return suggestedEnforcementRequests.findIndex(
      (request) => request.id === selectedEnforcement.id,
    )
  }

  const navigateEnforcements = async (direction: number) => {
    const currentIndex = getCurrentEnforcementIndex()
    const newIndex = currentIndex + direction
    if (newIndex >= 0 && newIndex < suggestedEnforcementRequests.length) {
      setSelectedEnforcement(suggestedEnforcementRequests[newIndex])
    }
    return newIndex == suggestedEnforcementRequests.length || newIndex < 0
  }

  const getEnforcementRequestsAndSetter = (enforcementStatus) => {
    switch (enforcementStatus) {
      case SuggestedEnforcementStatus.SUGGESTED:
        return [suggestedEnforcementRequests, setSuggestedEnforcementRequests]
      case SuggestedEnforcementStatus.REJECTED:
        return [rejectedEnforcementRequests, setRejectedEnforcementRequests]
      default:
        return [stagedEnforcementRequests, setStagedEnforcementRequests]
    }
  }

  const moveEnforcementUtil = ({ pos, from, to, newEnforcement = null }) => {
    const [fromEnforcementRequests, setFromEnforcementRequests] =
      getEnforcementRequestsAndSetter(from)
    const [toEnforcementRequests, setToEnforcementRequests] =
      getEnforcementRequestsAndSetter(to)
    moveEnforcement(
      pos,
      fromEnforcementRequests,
      setFromEnforcementRequests,
      toEnforcementRequests,
      setToEnforcementRequests,
      to,
      newEnforcement,
    )
  }

  if (showIndividualEnforcementDetails && selectedEnforcement) {
    return (
      <DetailModal
        handleClickOutside={() => {
          setShowIndividualEnforcementDetails(false)
          setSelectedEnforcement(null)
          setSelectedEnforcementPos(null)
          returnToTableView()
        }}
        overlayRef={overlayRef}
      >
        <IndividualSuggestedEnforcementDetails
          checkIfSuggestedEnforcementAlreadyActiveOrStaged={
            checkIfSuggestedEnforcementAlreadyActiveOrStaged
          }
          closeSuggestedEnforcementDetails={() => {
            setShowIndividualEnforcementDetails(false)
            setSelectedEnforcement(null)
            setSelectedEnforcementPos(null)
          }}
          navigateEnforcements={navigateEnforcements}
          product={product}
          rejectEnforcement={(newEnforcement) => {
            moveEnforcementUtil({
              pos: selectedEnforcementPos,
              from: SuggestedEnforcementStatus.SUGGESTED,
              to: SuggestedEnforcementStatus.REJECTED,
              newEnforcement: newEnforcement,
            })
            if (
              getCurrentEnforcementIndex() <
              suggestedEnforcementRequests.length - 1
            ) {
              setSelectedEnforcement(
                suggestedEnforcementRequests[getCurrentEnforcementIndex() + 1],
              )
            } else {
              setShowIndividualEnforcementDetails(false)
              setSelectedEnforcement(null)
              setSelectedEnforcementPos(null)
            }
          }}
          returnToTableView={returnToTableView}
          stageEnforcement={(newEnforcement) => {
            moveEnforcementUtil({
              pos: selectedEnforcementPos,
              from: SuggestedEnforcementStatus.SUGGESTED,
              to: SuggestedEnforcementStatus.STAGED,
              newEnforcement: newEnforcement,
            })
            if (
              getCurrentEnforcementIndex() <
              suggestedEnforcementRequests.length - 1
            ) {
              setSelectedEnforcement(
                suggestedEnforcementRequests[getCurrentEnforcementIndex() + 1],
              )
            } else {
              setShowIndividualEnforcementDetails(false)
              setSelectedEnforcement(null)
              setSelectedEnforcementPos(null)
            }
          }}
          suggestedEnforcement={selectedEnforcement}
        />
      </DetailModal>
    )
  }

  const isUserEnforcementValid = reasonsCreateNewEnforcementDisabled.length === 0
  const resetEnforcementCreation = () => {
    setCreatingNewEnforcement(!creatingNewEnforcement)
    setSelectedEnforcementType('')
    setSelectedEnforcementPlatformType('')
    setSelectedTrademarkId('')
    setSelectedContentType('')
    setSelectedTrademarkMark('')
  }

  const renderCreateNewEnforcementButton = () => {
    return (
      <Stack style={{ marginLeft: '20px' }}>
        {!creatingNewEnforcement && (
          <IconButton
            aria-label={'add_enforcement'}
            backgroundColor={DOPPEL_SUCCESS}
            color="white"
            icon={<IoMdAddCircle />}
            maxWidth={'5%'}
            onClick={() => {
              resetEnforcementCreation()
            }}
            size="sm"
          />
        )}

        {creatingNewEnforcement && (
          <Stack>
            <HStack>
              <DropdownSelector
                items={PRODUCT_TYPE_TO_ENFORCEMENT_TYPE[product]}
                onItemSelected={(value) => setSelectedEnforcementType(value)}
                placeholder="Enforcement Type"
                selectedItem={selectedEnforcementType}
                width="170px"
              />

              {product === ProductType.DOMAIN && (
                <DropdownSelector
                  idField={'value'}
                  items={getDropdownPlatformTypes()}
                  nameField={'label'}
                  onItemSelected={(value) => setSelectedEnforcementPlatformType(value)}
                  placeholder="Platform Type"
                  selectedItem={selectedEnforcementPlatformType}
                  width="170px"
                />
              )}

              {selectedEnforcementType === ENFORCEMENT_TYPE.TRADEMARK && (
                <Stack width={'340px'}>
                  {renderTrademarkSelector(
                    trademarks,
                    selectedTrademarkMark,
                    setSelectedTrademarkId,
                    setSelectedTrademarkMark,
                  )}
                </Stack>
              )}

              {product === ProductType.SOCIAL_MEDIA && (
                <DropdownSelector
                  items={Object.values(SocialMediaContentType)}
                  onItemSelected={(value) => setSelectedContentType(value)}
                  placeholder="Content Type"
                  selectedItem={selectedContentType}
                  width="170px"
                />
              )}
            </HStack>

            <HStack>
              {stageEnforcementButtonDisabledReasonsTooltip(
                <Button
                  bgColor={DOPPEL_CYBER_BLUE}
                  isDisabled={!isUserEnforcementValid}
                  onClick={() => {
                    generateUserEnforcement()
                  }}
                  size={'sm'}
                >
                  Add Enforcement
                </Button>,
                isUserEnforcementValid,
                reasonsCreateNewEnforcementDisabled,
                'add',
              )}

              <Button
                bgColor={DOPPEL_BUTTON_GREY}
                onClick={() => {
                  resetEnforcementCreation()
                }}
                size={'sm'}
              >
                Cancel
              </Button>
            </HStack>
          </Stack>
        )}
      </Stack>
    )
  }

  const renderEnforcementsSectionTitle = (title, color, icon) => {
    return (
      <Stack>
        <HStack>
          <IconButton
            aria-label={title}
            bgColor={color}
            icon={icon}
            marginLeft={2}
            size="sm"
          />

          <Heading as="h4" fontSize={18} size="sm">
            {title}
          </Heading>
        </HStack>

        <Box borderBottom="1px" borderColor="whiteAlpha.600" width="full" />
      </Stack>
    )
  }

  const renderReviewSection = () => {
    return (
      <>
        {renderEnforcementsSectionTitle(
          'Enforcements To Be Reviewed',
          DOPPEL_FIREWALL_ORANGE,
          <IoMdClipboard />,
        )}

        {
          <DisabledEnforcementsTable
            hiddenEnforcementNotGeneratedReasons={hiddenEnforcementNotGeneratedReasons}
            shownEnforcementNotGeneratedReasons={shownEnforcementNotGeneratedReasons}
          ></DisabledEnforcementsTable>
        }

        {renderEnforcementRequests(
          suggestedEnforcementRequests,
          (pos) => () =>
            moveEnforcementUtil({
              pos: pos,
              from: SuggestedEnforcementStatus.SUGGESTED,
              to: SuggestedEnforcementStatus.REJECTED,
            }),
          product,
          true,
          true,
          (enforcement, pos) => {
            if (!showIndividualEnforcementDetails) {
              setShowIndividualEnforcementDetails(true)
              setSelectedEnforcement(enforcement)
              setSelectedEnforcementPos(pos)
            }
          },
        )}
      </>
    )
  }

  const renderRejectedSection = () => {
    return (
      <>
        {renderEnforcementsSectionTitle(
          'Rejected Enforcements',
          DOPPEL_BREACH_RED,
          <IoMdClose />,
        )}

        {renderEnforcementRequests(
          rejectedEnforcementRequests,
          (pos) => () =>
            moveEnforcementUtil({
              pos: pos,
              from: SuggestedEnforcementStatus.REJECTED,
              to: SuggestedEnforcementStatus.SUGGESTED,
            }),
          product,
          false,
          true,
        )}
      </>
    )
  }

  const renderSuggestedSection = () => {
    return (
      <>
        {renderEnforcementsSectionTitle(
          'Staged Enforcements',
          DOPPEL_SUCCESS,
          <IoMdCheckmark />,
        )}

        {renderEnforcementRequests(
          stagedEnforcementRequests,
          (pos) => () =>
            moveEnforcementUtil({
              pos: pos,
              from: SuggestedEnforcementStatus.STAGED,
              to: SuggestedEnforcementStatus.REJECTED,
            }),
          product,
          true,
          true,
        )}
      </>
    )
  }

  const renderDoneReviewingButton = () => {
    return (
      <>
        <Tooltip
          label={
            suggestedEnforcementRequests.length > 0
              ? 'Please review (either stage or reject) all suggested enforcements before continuing.'
              : 'Will add enforcements that have been staged to the takedown request form.'
          }
          placement="top-start"
        >
          <span>
            <Button
              bgColor={DOPPEL_CYBER_BLUE}
              isDisabled={
                stagedEnforcementRequests.length === 0 ||
                suggestedEnforcementRequests.length > 0
              }
              onClick={() => {
                uploadSuggestedEnforcementsToDb()
                addSuggestedEnforcementsToForm(getCleanedStagedEnforcements())
                closeSuggestedEnforcementsDisplay()
              }}
              size={'md'}
            >
              Done Reviewing Enforcements
            </Button>
          </span>
        </Tooltip>
      </>
    )
  }

  const uploadSuggestedEnforcementsToDb = () => {
    _uploadSuggestedEnforcementsToDb(stagedEnforcementRequests)
    _uploadSuggestedEnforcementsToDb(rejectedEnforcementRequests)
  }

  const _uploadSuggestedEnforcementsToDb = (suggestedEnforcements) => {
    for (const enforcement of suggestedEnforcements) {
      updateSuggestedEnforcements({
        variables: {
          id: enforcement.suggested_enforcement_id,
          selected_platform_name: enforcement.selected_platform_name,
          provider_name: enforcement.provider_name,
          status: enforcement.status,
          allowed_enforcement_methods: enforcement.allowed_enforcement_methods,
          selected_method: enforcement.selected_method,
          selected_platform_selection_method:
            enforcement.selected_platform_selection_method,
          updated_by: userId,
          protected_content: enforcement.user_input?.protected_slug || '',
          flagged_content:
            enforcement.user_input?.slug ||
            selectedSpoofReport?.spoof_matches?.[0]?.social_media_data?.slug ||
            selectedSpoofReport?.social_media_data?.slug ||
            '',
          suggested_template_fields: enforcement.suggested_template_fields ?? null,
          unfilled_suggested_template: enforcement.unfilled_suggested_template ?? null,
        },
      })
    }
  }

  const getCleanedStagedEnforcements = () => {
    const cleanedEnforcements = []
    for (const enforcement of stagedEnforcementRequests) {
      cleanedEnforcements.push({
        type: enforcement.enforcement_type,
        platform: enforcement.provider_name,
        file: enforcement.file,
        userInput: enforcement.user_input,
        enforcementMethod:
          EnforcementMethodLabels[enforcement.selected_method.method_type],
        sameRootDomainAlertIds: enforcement.same_root_domain_alert_ids,
        suggestedEnforcementId: enforcement.suggested_enforcement_id,
      })
    }
    return cleanedEnforcements
  }

  return (
    <Flex width={'1000px'}>
      <DetailNavBar
        onBackPressed={closeSuggestedEnforcementsDisplay}
        onExitTapped={returnToTableView}
      ></DetailNavBar>

      <VStack
        align={'left'}
        marginBottom={'50px'}
        marginTop={'70px'}
        mx={'50px'}
        spacing={30}
        width="100%"
      >
        <Heading as="h4" fontSize={20} size="sm">
          Suggested Enforcements Display
        </Heading>

        {renderReviewSection()}

        {renderCreateNewEnforcementButton()}

        {renderRejectedSection()}

        {renderSuggestedSection()}

        {renderDoneReviewingButton()}
      </VStack>
    </Flex>
  )
}

export default withApollo<Props>(SuggestedEnforcementsDisplay)
