import { withApollo } from '@apollo/react-hoc'
import { Button, Checkbox, FormControl, FormLabel, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useSelectedSpoofReport } from '../../hooks/spoof_report_table_filters'
import {
  DOPPEL_CYBER_BLUE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../../utils/style'
import { useSelectedReport } from '../../hooks/report_table_filters'
import { useSendSlackMessageMutation } from '../../generated/graphql'
import { getOrgData, useEmail, useIsEmployeeView } from '../../hooks/id_token_claims'
import { sendCustomerReportedSlackMessage } from '../../hooks/slack_notifications'

// Used by the customer to report a case by signing a declaration statement. Ops then gets signaled to act on this and report internaly

type Props = {
  onCompleted: any
}

const ExternalEnforcementForm: React.FC<Props> = ({ onCompleted: onCompleted }) => {
  const [isSigned, setIsSigned] = useState(false)
  const [selectedReport] = useSelectedReport()
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()
  const [email] = useEmail()
  const [orgData] = getOrgData()
  const [isEmployeeView] = useIsEmployeeView()

  return (
    <>
      <FormControl>
        <Stack>
          <FormLabel fontSize={13}>Declaration Statement</FormLabel>

          <Text fontSize={12} maxWidth={'800px'}>
            By submitting this notice, you state that you have a good faith belief that
            the reported use described above, in the manner you have complained of, is
            not authorized by the intellectual property rights owner, its agent, or the
            law; that the information contained in this notice is accurate; and, under
            penalty of perjury, that you are authorized to act on behalf of the owner of
            the intellectual property rights at issue.
          </Text>
        </Stack>
      </FormControl>

      <FormControl isRequired={true}>
        <Stack>
          <FormLabel fontSize={13}>Electronic Signature</FormLabel>

          <Checkbox
            onChange={(e) => setIsSigned(e.target.checked)}
            value="electronic_signature"
          >
            <Text fontSize={13}>
              I understand and will comply with the declaration statement.
            </Text>
          </Checkbox>
        </Stack>
      </FormControl>

      <Button
        _hover={{ background: DOPPEL_INTERNAL_PURPLE }}
        bgColor={DOPPEL_CYBER_BLUE}
        color={DOPPEL_WHITE}
        isDisabled={!isSigned}
        onClick={() => {
          if (!isEmployeeView) {
            sendCustomerReportedSlackMessage(
              sendSlackMessageMutation,
              email,
              orgData,
              selectedReport?.collection_slug || selectedSpoofReport?.flagged_url,
              selectedReport?.id || selectedSpoofReport?.id,
              selectedSpoofReport?.platform?.product || 'nfts',
              'Reported',
            )
          }
          onCompleted()
        }}
        width={'200px'}
      >
        Submit
      </Button>
    </>
  )
}

export default withApollo<Props>(ExternalEnforcementForm)
