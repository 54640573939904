import {
  EnforcementMethodTuple,
  FormFieldTuple,
} from '@/utils/suggested_enforcements/suggested_enforcement_types'
import { FormControl, Heading, VStack } from '@chakra-ui/react'
import { ReactElement, useState } from 'react'
import DoppelFormLabel from '@/components/shared/forms/doppel_form_label'
import TextInputField from '@/components/shared/text_input_field'
import { FormFieldsOptions } from '@/generated/enums'
import { TemplateSection } from '@/components/enforcements/enforcement_template'
import useTemplateInfoInputs, {
  useFetchTemplateInput,
} from '@/components/enforcements/populate_template_helper'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import { Spoofing_Reports } from '@/generated/graphql'

type SingleFieldDisplayProps = {
  fieldInfo: FormFieldTuple
}

type ManualFormFieldsDisplayProps = {
  selectedEnforcementMethod?: EnforcementMethodTuple
  selectedEnforcementPlatform?: any
  submissionEmail?: string
  linkedAlertData?: Spoofing_Reports[]
}

// Displays a bunch of copy-pastable form fields under the manual enforcement section
function ManualFormFieldsDisplay({
  selectedEnforcementMethod,
  selectedEnforcementPlatform,
  submissionEmail,
  linkedAlertData,
}: ManualFormFieldsDisplayProps): ReactElement {
  const [templateInfoInputs, setTemplateInfoInputs] = useState(null)
  const formFields = selectedEnforcementMethod?.form_fields
  const [selectedSpoofReport, _] = useSelectedSpoofReport()
  const { templateInput } = useFetchTemplateInput(
    selectedSpoofReport,
    selectedEnforcementPlatform,
  )
  useTemplateInfoInputs({
    templateInput,
    setTemplateInfoInputs,
    selectedEnforcementPlatform,
    submissionEmail,
    // Don't need to defang urls in manual forms
    shouldDefangUrls: false,
    selectedSpoofReport,
    linkedAlertData,
  })

  if (!formFields || formFields.length === 0) {
    return <></>
  }

  function SingleFieldDisplay({ fieldInfo }: SingleFieldDisplayProps): ReactElement {
    if (fieldInfo.field_value_type === FormFieldsOptions.MESSAGE_TEMPLATE) {
      return (
        <TemplateSection
          label={fieldInfo.field_name}
          moreInfoInputs={templateInfoInputs}
          template={fieldInfo.field_value}
        />
      )
    }

    return (
      <FormControl key={fieldInfo.field_name}>
        <VStack align="left">
          <DoppelFormLabel formLabel={fieldInfo.field_name} />

          <TextInputField dbValue={fieldInfo.field_value} />
        </VStack>
      </FormControl>
    )
  }

  return (
    <VStack align={'left'}>
      <Heading as="h4" fontSize={20} size="sm">
        Form Fields Suggestions
      </Heading>

      {formFields.map((field) => (
        <SingleFieldDisplay fieldInfo={field} key={field.field_name} />
      ))}
    </VStack>
  )
}

export default ManualFormFieldsDisplay
