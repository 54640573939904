import { withApollo } from '@apollo/react-hoc'
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Link,
  List,
  ListItem,
  Select,
  Spacer,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import SpoofDetailImagePreview from '../web2/detail_modal/spoof_detail_image_preview'
import {
  renderFlaggedUrl,
  renderReasonsStagingDisabled,
  renderUploadScreenshot,
  stageEnforcementButtonDisabledReasonsTooltip,
  STAGING_ONLY_PLATFORMS,
} from '@/utils/enforcement_utils'
import { useSelectedSpoofReport } from '@/hooks/spoof_report_table_filters'
import DoppelFormLabel from '../shared/forms/doppel_form_label'
import { useEffect, useState } from 'react'
import {
  AppEnforcementPlatformType,
  EnforcementMethod,
  EnforcementType,
  PlatformName,
  PlatformSelectionMethod,
  ProductType,
  StagingDisabledReason,
  SuggestedEnforcementStatus,
} from '@/generated/enums'
import AutomatedTakedownSection from './automated_takedown_section'
import { ENFORCEMENT_TYPE, EntityType } from '@/utils/constants'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_CLOUD_BLUE,
  DOPPEL_LINK,
  DOPPEL_SUCCESS,
} from '@/utils/style'
import { TRADEMARK_ID } from './enforcement_user_input'
import DetailNavBar from '../shared/back_button'
import { useShouldDisableArrowNavigation } from '@/pages/reports'
import { IoMdCloseCircle } from 'react-icons/io'
import {
  EnforcementMethodTuple,
  SuggestedEnforcement,
} from '@/utils/suggested_enforcements/suggested_enforcement_types'
import {
  updateFile,
  updateSelectedMethod,
  updateSelectedMethodData,
  updateSuggestedEnforcementViaPlatformPicker,
  updateTemplateData,
  updateUserInput,
} from '@/utils/suggested_enforcements/update_suggested_enforcement_utils'
import {
  getDomainsPlatformTypeLabel,
  getEnforcementRequestLabel,
} from '@/utils/suggested_enforcements/display_enforcement_utils'
import EnforcementPlatformMenu from './enforcement_platform_menu'
import { getDoppelLink } from '@/utils/reports/report_utils'
import { useGetEnforcementLinkedAlertInfoLazyQuery } from '@/generated/graphql'
import ManualFormFieldsDisplay from './manual_form_fields_display'
import { setLogVerbosity } from '@apollo/client'
setLogVerbosity('debug')
type Props = {
  suggestedEnforcement: SuggestedEnforcement
  navigateEnforcements: (direction: number) => Promise<boolean>
  stageEnforcement: any
  rejectEnforcement: any
  closeSuggestedEnforcementDetails: any
  returnToTableView: any
  useDefaults?: boolean
  client: any
  checkIfSuggestedEnforcementAlreadyActiveOrStaged: any
  product: any
}

const IndividualSuggestedEnforcementDetails: React.FC<Props> = ({
  suggestedEnforcement,
  navigateEnforcements,
  stageEnforcement,
  rejectEnforcement,
  closeSuggestedEnforcementDetails,
  returnToTableView,
  checkIfSuggestedEnforcementAlreadyActiveOrStaged,
  product,
}) => {
  const [selectedSpoofReport] = useSelectedSpoofReport()
  const [transitioning, setTransitioning] = useState(false)

  const [reasonsStagingDisabled, setReasonsStagingDisabled] = useState([])
  const [_, setShouldDisableArrowNavigation] = useShouldDisableArrowNavigation()
  const [alreadyActiveOrStagedReason, setAlreadyActiveOrStagedReason] = useState(null)
  const toast = useToast()

  const [linkedAlertData, setLinkedAlertData] = useState([])

  const [getLinkedAlertDataQuery, { loading: linkedAlertsLoading }] =
    useGetEnforcementLinkedAlertInfoLazyQuery()

  const [enforcementState, setEnforcementState] =
    useState<SuggestedEnforcement>(suggestedEnforcement)

  const selectedEnforcementMethod = enforcementState.selected_method
  const selectedEnforcementMethodType = selectedEnforcementMethod.method_type
  const selectedEnforcementMethodData = selectedEnforcementMethod.method_data

  const enforcementType = enforcementState.enforcement_type

  const userInput = enforcementState.user_input
  const trademarkId =
    enforcementType == ENFORCEMENT_TYPE.TRADEMARK ? userInput?.[TRADEMARK_ID] : null
  const allowedEnforcementMethods = enforcementState.allowed_enforcement_methods
  const notes = enforcementState.notes
  const file = enforcementState.file

  const selectedPlatformSelectionMethod =
    enforcementState.selected_platform_selection_method
  const platformType = enforcementState.platform_type

  const providerName = enforcementState.provider_name
  const selectedPlatformName = enforcementState.selected_platform_name

  const isPlatformKnown =
    selectedPlatformSelectionMethod === PlatformSelectionMethod.PLATFORM_KNOWN
  const enforcementDescriptionLabel = getEnforcementRequestLabel(
    suggestedEnforcement,
    product,
    true,
  )

  const sameRootDomainAlertIds = enforcementState.same_root_domain_alert_ids || []

  const updateEnforcementStateUsingFunction = (updateFunction, ...params) => {
    setEnforcementState((prevState) => {
      const updatedVal = updateFunction(prevState, ...params)
      return updatedVal
    })
  }

  const getUpdateFieldsSetter = (updateFunction) => {
    return (...fields) => {
      updateEnforcementStateUsingFunction(updateFunction, ...fields)
    }
  }

  const getUpdateUserInputSetter = () => {
    return (newInput) => {
      // Handle function case: if newInput is a function, call it with the current state
      if (typeof newInput === 'function') {
        const evaluatedNewInput = newInput(userInput) // Call newInput as a function with current user_input state
        return updateEnforcementStateUsingFunction(updateUserInput, evaluatedNewInput)
      } else {
        // Otherwise, treat it as a direct object and merge
        updateEnforcementStateUsingFunction(updateUserInput, newInput)
      }
    }
  }

  useEffect(() => {
    setShouldDisableArrowNavigation(true)
  }, [])

  useEffect(() => {
    resetState()
  }, [suggestedEnforcement])

  useEffect(() => {
    if (
      selectedPlatformName === PlatformName.TWITTER &&
      enforcementType === EnforcementType.IMPERSONATION &&
      selectedEnforcementMethodType === EnforcementMethod.MANUAL_FORM &&
      selectedEnforcementMethodData?.includes(',')
    ) {
      const impersonationFormLinks = selectedEnforcementMethodData.split(',')
      const entity_type = selectedSpoofReport?.spoofing_entity?.entity_type
      if (entity_type === EntityType.ORGANIZATION_BRAND) {
        updateEnforcementStateUsingFunction(
          updateSelectedMethodData,
          impersonationFormLinks?.[0],
        )
      } else if (entity_type === EntityType.PERSONAL_BRAND) {
        updateEnforcementStateUsingFunction(
          updateSelectedMethodData,
          impersonationFormLinks?.[1],
        )
      }
    }
  }, [
    selectedPlatformName,
    enforcementType,
    selectedEnforcementMethodData,
    selectedEnforcementMethodType,
  ])

  useEffect(() => {
    const getLinkedAlertData = async () => {
      await getLinkedAlertDataQuery({
        variables: { alert_ids: sameRootDomainAlertIds },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
          setLinkedAlertData(data.spoofing_reports)
        },
      })
    }
    getLinkedAlertData()
  }, [])

  const handleBackPressed = () => {
    setShouldDisableArrowNavigation(false)
    closeSuggestedEnforcementDetails()
  }

  const handleExitTapped = () => {
    setShouldDisableArrowNavigation(false)
    returnToTableView()
  }

  const resetState = () => {
    setEnforcementState(suggestedEnforcement)
  }

  const isAnyAutoEnforcementEnabled =
    selectedEnforcementMethodType === EnforcementMethod.AUTO_EMAIL ||
    selectedEnforcementMethodType === EnforcementMethod.AUTO_FORM ||
    selectedEnforcementMethodType === EnforcementMethod.API ||
    selectedEnforcementMethodType === EnforcementMethod.OTHER
  const isAnyManualEnforcementEnabled =
    selectedEnforcementMethodType === EnforcementMethod.MANUAL_EMAIL ||
    selectedEnforcementMethodType === EnforcementMethod.MANUAL_FORM
  const isEnforcementValid = reasonsStagingDisabled.length === 0

  const isManualEnforcementScreenshotRequired =
    isAnyManualEnforcementEnabled &&
    !STAGING_ONLY_PLATFORMS.includes(suggestedEnforcement.provider_name)

  const shouldRenderEnforcementMethodSelector =
    isManualEnforcementScreenshotRequired || isAnyAutoEnforcementEnabled

  useEffect(() => {
    const handleKeyDown = async (event) => {
      if (!suggestedEnforcement) return

      switch (event.key) {
        case 'ArrowLeft':
          handleTransition('left')
          break
        case 'ArrowRight':
          handleTransition('right')
          break
        case 'Escape':
          closeSuggestedEnforcementDetails()
          break
        default:
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [suggestedEnforcement])

  useEffect(() => {
    if (!selectedPlatformName) {
      setReasonsStagingDisabled([StagingDisabledReason.MISSING_PLATFORM])
    } else {
      const newAlreadyActiveOrStagedReason =
        checkIfSuggestedEnforcementAlreadyActiveOrStaged(enforcementState)
      setAlreadyActiveOrStagedReason(newAlreadyActiveOrStagedReason)
      if (newAlreadyActiveOrStagedReason) {
        setReasonsStagingDisabled([newAlreadyActiveOrStagedReason])
      } else {
        const reasons = []
        if (!selectedEnforcementMethod) {
          reasons.push(StagingDisabledReason.MISSING_ENFORCEMENT_METHOD)
        }
        if (
          isAnyManualEnforcementEnabled &&
          isManualEnforcementScreenshotRequired &&
          !file
        ) {
          reasons.push(StagingDisabledReason.MISSING_SCREENSHOT)
        }
        setReasonsStagingDisabled(reasons)
      }
    }
  }, [
    selectedEnforcementMethod,
    selectedPlatformName,
    isAnyManualEnforcementEnabled,
    file,
    selectedSpoofReport,
  ])

  const renderEnforcementPlatform = () => {
    return (
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Enforcement Platform"
          helperText={
            isPlatformKnown
              ? 'The platform that the enforcement will be sent to'
              : 'The platform that the enforcement will be sent to. Please edit/format the platform name properly before sending the enforcement.'
          }
        />

        {isPlatformKnown && <Input isDisabled={true} value={selectedPlatformName} />}

        {!isPlatformKnown && (
          <Box>
            <EnforcementPlatformMenu
              product={product}
              setSuggestedEnforcementViaPlatformPicker={getUpdateFieldsSetter(
                updateSuggestedEnforcementViaPlatformPicker,
              )}
              suggestedEnforcement={enforcementState}
            />
          </Box>
        )}
      </VStack>
    )
  }

  const renderFinalInstructions = () => {
    return isManualEnforcementScreenshotRequired ? (
      <VStack align="left">
        <FormControl isRequired>
          <DoppelFormLabel
            formLabel="Suggested Manual Action"
            helperText={
              'Please review the enforcement details and ensure that all the information is correct before sending the enforcement.'
            }
          />
        </FormControl>

        <Text fontSize={15} fontWeight={'bold'} textAlign={'left'}>
          {selectedEnforcementMethodType === EnforcementMethod.MANUAL_EMAIL ? (
            <>
              Please email the relevant enforcement details to the platform. <Spacer />
              {selectedEnforcementMethodData ? (
                <>
                  {`Suggested email address is ${selectedEnforcementMethodData}. Please verify the email address is correct for the platform '${selectedPlatformName}' before sending the email.`}
                </>
              ) : (
                'No email address found, please search for the email address of the platform manually.'
              )}
            </>
          ) : (
            <>
              Please fill out the form with the relevant details for this enforcement
              request. <Spacer />
              {selectedEnforcementMethodData ? (
                <Link
                  color={DOPPEL_CLOUD_BLUE}
                  href={selectedEnforcementMethodData}
                  isExternal
                >
                  Go To Form
                </Link>
              ) : (
                'No form link found, please search for the form link of the platform manually.'
              )}
            </>
          )}
        </Text>

        {selectedEnforcementMethodType === EnforcementMethod.MANUAL_FORM && (
          <VStack align="left">
            <Spacer />

            <ManualFormFieldsDisplay
              linkedAlertData={linkedAlertData}
              selectedEnforcementMethod={selectedEnforcementMethod}
              selectedEnforcementPlatform={selectedPlatformName}
              submissionEmail={selectedEnforcementMethodData}
            />

            <Spacer />
          </VStack>
        )}

        {renderUploadScreenshot(file, getUpdateFieldsSetter(updateFile))}
      </VStack>
    ) : (
      <Text fontSize={15} fontWeight={'bold'} textAlign={'left'}>
        Creating manual request to platform. Do not fill out the form/email for the
        platform at this time, no screenshot is required. Enforcement request
        submissions will move alert to staging. A Doppel member with permissions will
        upload screenshots and submit. No further action is needed.
      </Text>
    )
  }

  const renderPlatformNotes = () => {
    return (
      <VStack align="left">
        <DoppelFormLabel
          formLabel="Additional Notes"
          helperText={
            'Please review the notes for the platform and ensure that all the information is correct before sending the enforcement.'
          }
        />

        <Text fontSize={15} fontWeight={'bold'} textAlign={'left'}>
          {notes}
        </Text>
      </VStack>
    )
  }

  const handleEnforcementMethodSelection = (e) => {
    const newEnforcementMethod = JSON.parse(e.target.value) as EnforcementMethodTuple

    // Update the enforcement state with the selected method and its data
    updateEnforcementStateUsingFunction(updateSelectedMethod, newEnforcementMethod)
  }

  const renderEnforcementMethodSelector = () => (
    <FormControl isRequired>
      <Stack>
        <DoppelFormLabel
          formLabel="Enforcement Method"
          helperText="Please select your preferred enforcement method. These method options have been ranked from top to bottom by the preferred method for the platform, so please choose accordingly. If you are unsure, please select 'Manual Email' or 'Manual Form' and follow the instructions provided."
        />

        {enforcementType == EnforcementType.TRADEMARK &&
          userInput?.[TRADEMARK_ID] === '' && (
            <Text fontSize={15} fontWeight={'bold'} textAlign={'left'}>
              {`No trademark selected, api and auto-email enforcements are not allowed. Please select from manual enforcement methods below.`}
            </Text>
          )}

        <Select
          onChange={(e) => {
            handleEnforcementMethodSelection(e)
          }}
          value={JSON.stringify(selectedEnforcementMethod)} // Serialize the current selection as string
        >
          {allowedEnforcementMethods.map((enforcementMethod) => (
            <option
              key={enforcementMethod.method_type}
              value={JSON.stringify(enforcementMethod)} // Serialize the entire object into a string
            >
              {enforcementMethod.method_type}
            </option>
          ))}
        </Select>
      </Stack>
    </FormControl>
  )

  const getUpdatedEnforcement = (status) => {
    return {
      ...enforcementState,
      status: status,
    }
  }
  const handleTransition = async (action) => {
    setTransitioning(true)

    const performNavigation = async (direction) => {
      const isAtEndOfEnforcementsList = await navigateEnforcements(direction)
      if (isAtEndOfEnforcementsList) {
        closeSuggestedEnforcementDetails()
      }
    }

    const updateEnforcement = async (action) => {
      if (action === 'approve') {
        await stageEnforcement(getUpdatedEnforcement(SuggestedEnforcementStatus.STAGED))
      } else if (action === 'reject') {
        await rejectEnforcement(
          getUpdatedEnforcement(SuggestedEnforcementStatus.REJECTED),
        )
      }
    }

    setTimeout(async () => {
      if (action === 'approve' || action === 'reject') {
        await updateEnforcement(action)
        await performNavigation(1)
      } else if (action === 'left') {
        await performNavigation(-1)
      } else if (action === 'right') {
        await performNavigation(1)
      }

      resetState()
      setTransitioning(false)
    }, 500)
  }

  const renderStageButton = () => {
    return (
      <Button
        bgColor={DOPPEL_SUCCESS}
        isDisabled={!isEnforcementValid}
        onClick={() => {
          toast({
            title: 'Staged Enforcement Request',
            description: `Successfully staged ${enforcementDescriptionLabel}`,
            status: 'success',
            isClosable: true,
          })
          handleTransition('approve')
        }}
      >
        Stage Enforcement
      </Button>
    )
  }

  const renderLinkedAlertsSection = (linkedAlertData) => {
    return (
      <Stack>
        <Heading as="h3" fontSize={20} size="sm">
          Will also submit enforcement for following alerts with same root domain:
        </Heading>

        <List>
          {linkedAlertData.map((report) => (
            <ListItem key={report.id}>
              <Link
                color={DOPPEL_LINK}
                cursor="pointer"
                href={getDoppelLink(
                  report.id,
                  product,
                  report.organization?.abbr_name,
                  report.external_id,
                )}
                isExternal
              >
                {report.flagged_url}
              </Link>
            </ListItem>
          ))}
        </List>
      </Stack>
    )
  }

  const renderPlatformTypeNotFoundInstructions = () => {
    const ipAddress =
      selectedSpoofReport?.first_domain_data?.[0]?.full_url?.domain_data?.ip_address ||
      ''
    const whoIsLink = 'https://www.whois.com/whois/'
    const platformTypeLabel = getDomainsPlatformTypeLabel(platformType)
    return (
      <Stack>
        <Heading as="h4" fontSize={17} size="sm">
          Please find the {platformTypeLabel} for this alert if possible using the
          domain info search tools below and fill in the platform name.
        </Heading>

        <Heading as="h4" fontSize={16} size="sm">
          Recommended Domain Info Search Tools:
        </Heading>

        <Stack direction="row" spacing={4}>
          <Heading as="h4" fontSize={15} size="sm">
            WhoIs Lookups for This Alert:
          </Heading>

          {ipAddress && (
            <Link
              color={DOPPEL_CLOUD_BLUE}
              href={`${whoIsLink}${ipAddress}`}
              isExternal
            >
              WhoIs IP Address Lookup
            </Link>
          )}

          <Link
            color={DOPPEL_CLOUD_BLUE}
            href={`${whoIsLink}${selectedSpoofReport?.flagged_url}`}
            isExternal
          >
            WhoIs Entity Lookup
          </Link>
        </Stack>

        <Stack direction="row" spacing={4}>
          <Heading as="h4" fontSize={15} size="sm">
            Other Domain Info Search Tools:
          </Heading>

          <Link color={DOPPEL_CLOUD_BLUE} href="https://centralops.net/co/" isExternal>
            CentralOps
          </Link>

          <Link color={DOPPEL_CLOUD_BLUE} href="https://hostingchecker.com/" isExternal>
            HostingChecker
          </Link>

          <Link color={DOPPEL_CLOUD_BLUE} href="https://phish.report/" isExternal>
            PhishReport
          </Link>
        </Stack>
      </Stack>
    )
  }

  const shouldRenderStagingDisabledReasons = reasonsStagingDisabled?.length > 0

  return (
    <Flex
      opacity={transitioning ? 0 : 1}
      transition="opacity 0.5s ease-in-out"
      width={'1000px'}
    >
      <DetailNavBar onBackPressed={handleBackPressed} onExitTapped={handleExitTapped} />

      <VStack
        align={'left'}
        marginBottom={'50px'}
        marginTop={'70px'}
        mx={'50px'}
        spacing={30}
        width="100%"
      >
        <Heading as="h4" fontSize={20} size="sm">
          Individual Enforcement Details
        </Heading>

        <SpoofDetailImagePreview isCondensedView={true} />

        <Heading as="h4" fontSize={18} size="sm">
          Creating a {enforcementDescriptionLabel}
        </Heading>

        {!selectedPlatformName &&
          product == ProductType.DOMAIN &&
          renderPlatformTypeNotFoundInstructions()}

        {renderEnforcementPlatform()}

        {selectedPlatformName && !alreadyActiveOrStagedReason && (
          <>
            {shouldRenderEnforcementMethodSelector && renderEnforcementMethodSelector()}

            {!isAnyAutoEnforcementEnabled &&
              renderFlaggedUrl(selectedSpoofReport?.flagged_url)}

            {notes && renderPlatformNotes()}

            {isAnyManualEnforcementEnabled && renderFinalInstructions()}

            {selectedEnforcementMethodType === EnforcementMethod.AUTO_EMAIL &&
              platformType === AppEnforcementPlatformType.THIRD_PARTY_APK && (
                <DoppelFormLabel
                  fontSize={'18'}
                  formLabel="Note: URL will be submitted as part of bulk email to platform."
                  helperText="The template shown below is for the overall bulk email to the platform, do not worry if the fields in the template are not filled out yet. The bulk email to the platform including this url will be sent within the next day (24 hours)"
                ></DoppelFormLabel>
              )}

            {isAnyAutoEnforcementEnabled && (
              <AutomatedTakedownSection
                customPlatform={
                  selectedPlatformSelectionMethod === PlatformSelectionMethod.CUSTOM
                }
                enforcementPlatform={providerName}
                enforcementUserInput={userInput}
                isAutoEmailEnforcementEnabled={
                  selectedEnforcementMethodType === EnforcementMethod.AUTO_EMAIL
                }
                linkedAlertData={linkedAlertData}
                platformType={platformType}
                selectedEnforcementMethodType={selectedEnforcementMethodType}
                selectedEnforcementPlatform={selectedPlatformName}
                selectedEnforcementType={enforcementType}
                setEnforcementUserInput={getUpdateUserInputSetter()}
                setReasonsStagingDisabled={setReasonsStagingDisabled}
                setSubmissionEmail={getUpdateFieldsSetter(updateSelectedMethodData)}
                setTemplateInfo={getUpdateFieldsSetter(updateTemplateData)}
                submissionEmail={selectedEnforcementMethodData}
                trademarkId={trademarkId}
              />
            )}
          </>
        )}

        <Spacer />

        {linkedAlertData.length > 0 && renderLinkedAlertsSection(linkedAlertData)}

        {shouldRenderStagingDisabledReasons &&
          renderReasonsStagingDisabled(reasonsStagingDisabled)}

        <Flex alignItems="center" justifyContent="space-between">
          {stageEnforcementButtonDisabledReasonsTooltip(
            renderStageButton(),
            isEnforcementValid,
            reasonsStagingDisabled,
          )}

          <Button
            bgColor={DOPPEL_BREACH_RED}
            onClick={() => {
              toast({
                title: 'Rejected Enforcement Request',
                description: `Successfully rejected ${enforcementDescriptionLabel}`,
                icon: <IoMdCloseCircle />,
                status: 'success',
                isClosable: true,
              })
              handleTransition('reject')
            }}
          >
            Reject Enforcement
          </Button>
        </Flex>

        {/*TODO: render additional information for manual enforcements such as trademark holder information*/}
      </VStack>
    </Flex>
  )
}

export default withApollo<Props>(IndividualSuggestedEnforcementDetails)
